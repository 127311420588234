import { invenseAxios } from '../../../utils/axios';

const headers = {
  "Content-Type": "application/json",
};

const validate200 = (status: number) => status === 200;
const validate201 = (status: number) => status === 200;

export const login = (username: string, password: string) => {
  const url = '/v1/user/login'
  const method = 'POST';
  const data = {username, password};
  return invenseAxios({
    url,
    method,
    headers,
    data,
    validateStatus: validate200,
    responseType: 'json'
  }).then(res => {
    return res.data?.item;
  }).catch(error => {
    console.log("API error", { url, method, error })
    return null;
  })
}

export const signup = (name: string, username: string, password: string) => {
  const url = '/v1/user/signup'
  const method = 'POST';
  const data = {username, password, name};
  return invenseAxios({
    url,
    method,
    headers,
    data,
    validateStatus: validate201,
    responseType: 'json'
  }).then(res => {
    return res.data?.item;
  }).catch(error => {
    console.log("API error", { url, method, error })
    return null;
  })
}
