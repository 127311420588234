//List Actions
export const FETCH_LISTDEMO_BEGIN = 'FETCH_LISTDEMO_BEGIN';
export const FETCH_LISTDEMO_SUCCESS = 'FETCH_LISTDEMO_SUCCESS';
export const FETCH_LISTDEMO_FAILURE = 'FETCH_LISTDEMO_FAILURE';
export const GET_ALL_COMPANY = 'GET_ALL_COMPANY';
export const UPDATE_ALL_COMPANY = 'UPDATE_ALL_COMPANY';
export const UPDATE_SELECTED_ORGANIZATION = 'UPDATE_SELECTED_ORGANIZATION'
export const UPDATE_PROJECTS = "UPDATE_PROJECTS";
export const UPDATE_PANELS = "UPDATE_PANELS";
export const UPDATE_DEVICES = "UPDATE_DEVICES";
export const UPDATE_USERS = "UPDATE_USERS";
export const UPDATE_ORGANIZATION_ID = "UPDATE_ORGANIZATION_ID";
export const UPDATE_LOGEDIN_USERDETAILS = "UPDATE_LOGEDIN_USERDETAILS";

//Other Actions...