import React from "react";
import Loader from "../../assets/img/loader.gif";

const Loading = () => {
  return (
    <div className=" h-16 w-16">
      <img className="w-full h-full" src={Loader} alt="loading" />
    </div>
  );
};

export default Loading;
